
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Feed</h1>
              <p class="lead">Example for activity feed
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-feed">
      <div class="container">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class=" feed-comment">
                      <div class="feed-header  has-desc ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Lena Wagner
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              vor 5 Stunden
                                          </span>
                                      </div>
                                      <p class="mb-0 desc">hat <a href="#"><strong>Robert Riedlmayer</strong></a> kommentiert</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <p>breit aufgestellt. Lässt sich anhand der Skills schwer einschätzen. Daher gerne mehr Info.</p>
                          <p>
                              Durch flexible Arbeitsmodelle ermöglicht es die GLS Bank Ihnen, mobil zu arbeiten. Bei den Präsenzterminen kommen Sie und Ihre Kolleg:innen ca. 2 Tage im Monat in Bochum zusammen. Die übrigen Tage können Sie an den Standorten in Bochum, Berlin, Frankfurt, Freiburg, Hamburg, München, Stuttgart oder mobil verbringen
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-comment">
                      <div class="feed-header  has-desc ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <span class="avatar-title bg-pastel-rose">PH</span>
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Peter Hagen
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              vor 5 Stunden
                                          </span>
                                      </div>
                                      <p class="mb-0 desc">hat <a href="#"><strong>Robert Riedlmayer</strong></a> kommentiert</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <p>
                              In Ihrer Rolle als IT-Application Specialist:in sind Sie für die Konzeption von Front- und Backend der Klickstrecken auf Basis der Anforderungen der Kund*innen und internen Fachbereiche verantwortlich.
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-highlight">
                      <div class="feed-header ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/lars-branscheid.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Lars Branscheid
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              gestern
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <div class="card card-sm">
                              <div class="card-body">
                                  hat <a href="#"><strong>Robert Riedlmayer</strong></a> auf die Shortlist gesetzt
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-highlight">
                      <div class="feed-header ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/lars-branscheid.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Lars Branscheid
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              gestern
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <div class="card card-sm">
                              <div class="card-body">
                                  hat <a href="#"><strong>Janis Boje</strong></a> auf die Shortlist gesetzt
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-highlight">
                      <div class="feed-header ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/patrick-eschler.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Patrick Eschler
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              gestern
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <div class="card card-sm">
                              <div class="card-body">
                                  <svg class="svg-inline--fa fa-circle-check me-2 text-success" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><!-- <i class="fa-solid fa-circle-check me-2 text-success"></i> --> hat die Jobsite freigeben
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-link">
                      <div class="feed-header ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/patrick-eschler.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Patrick Eschler
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              gestern
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <a href="#" class="card card-sm" style="width: 35rem;">
                              <div class="img-container hover-zoom">
                                  <img src="assets/img/cards/card-img-top.jpg" class="card-img-top" alt="...">
                              </div>
                              <div class="card-body">
                                  <h6 class="card-title mb-0">Jobsite IT-Application Specialist m|w|d</h6>
                                  <span class="text-muted small">https://app.skillconomy.com/projects/2461/job</span>
                              </div>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class=" feed-highlight">
                      <div class="feed-header ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/patrick-eschler.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Patrick Eschler
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              am 20.10.22 um 12:53 Uhr
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="feed-body">
                          <div class="card card-sm">
                              <div class="card-body">
                                  <svg class="svg-inline--fa fa-circle-check me-2 text-success" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg><!-- <i class="fa-solid fa-circle-check me-2 text-success"></i> --> hat das Briefing beendet
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
