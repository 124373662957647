import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { ScUiModule, ApiConfig } from '@sc-ui';
import { ScChatbotModule } from '@sc-ui-chatbot';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ClipboardModule } from 'ngx-clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {  HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import {  CodeFromUrlPipe, HighlightPlusModule } from 'ngx-highlightjs/plus';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { environment } from './environment.prod';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { StartComponent } from './views/start/start.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TypographyComponent } from './views/typography/typography.component';
import { CodeSamplePipe } from './code-sample.pipe';
import { AlertsComponent } from './views/alerts/alerts.component';
import { PaletteComponent } from './views/palette/palette.component';
import { ButtonsComponent } from './views/buttons/buttons.component';
import { BordersComponent } from './views/borders/borders.component';
import { BoxShadowsComponent } from './views/box-shadows/box-shadows.component';
import { SpacingComponent } from './views/spacing/spacing.component';
import { FormsComponent } from './views/forms/forms.component';
import { AvatarsComponent } from './views/avatars/avatars.component';
import { BadgesComponent } from './views/badges/badges.component';
import { TooltipsComponent } from './views/tooltips/tooltips.component';
import { ProgressComponent } from './views/progress/progress.component';
import { ModalComponent } from './views/modal/modal.component';
import { CardsComponent } from './views/cards/cards.component';
import { FeedComponent } from './views/feed/feed.component';
import { ListGroupComponent } from './views/list-group/list-group.component';
import { ChatbotComponent } from './views/chatbot/chatbot.component';
import { ConfettiComponent } from './views/confetti/confetti.component';

registerLocaleData(localeDe);



@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    TopbarComponent,
    SidebarComponent,
    TypographyComponent,
    CodeSamplePipe,
    AlertsComponent,
    PaletteComponent,
    ButtonsComponent,
    BordersComponent,
    BoxShadowsComponent,
    SpacingComponent,
    FormsComponent,
    AvatarsComponent,
    BadgesComponent,
    TooltipsComponent,
    ProgressComponent,
    ModalComponent,
    CardsComponent,
    FeedComponent,
    ListGroupComponent,
    ChatbotComponent,
    ConfettiComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    CommonModule,  
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBajkLHFeKGPwkvXBqq_GUb9CWv_nyar6g', libraries: ["places"], language: 'de' }),
    ScUiModule,
    ScChatbotModule,
    MonacoEditorModule.forRoot(),
    ClipboardModule,
    DragDropModule,
    InlineSVGModule,
    NgJsonEditorModule,
    ImageCropperModule,
    PdfViewerModule,
    HighlightPlusModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de-DE" },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js')
      }
    },
    {
      provide: ApiConfig,
      useValue:
      { 
        host: environment.api
      }
    },
    {
      provide : NgbModalConfig,
      useValue : {   
        animation: true,     
        centered: true
      }
    }
  ],
  bootstrap: [AppComponent], 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {

  constructor()
  {
  }
 }
