<main>
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Cards</h1>
              <p class="lead">Cards provide a flexible and extensible content container with multiple variants and options.
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class=" doc-html">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class="card" style="width: 30rem;">
                      <img src="assets/img/cards/card-img-top.jpg" class="card-img-top" alt="...">
                      <div class="card-body">
                          <h5 class="card-title">Card title</h5>
                          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                          <a href="#" class="btn btn-secondary btn-sm">Go somewhere</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
    <div class=" doc-html">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="card card-lg" style="width: 50rem;">
                    <img src="assets/img/cards/card-img-top.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" class="btn btn-secondary btn-sm">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <section class=" doc-section">
      <div class=" doc-html">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class="card">
                      <div class="card-header">
                          <div class="row align-items-center">
                              <div class="col">
                                  <h6 class="card-header-title">Teilnehmer</h6>
                              </div>
                              <div class="col-auto">
                                  <div class="btn btn-sm btn-secondary btn-circle">
                                      <svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fasr" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M248 72V48H200V72 232H40 16v48H40 200V440v24h48V440 280H408h24V232H408 248V72z"></path></svg><!-- <i class="fa-sharp fa-regular fa-plus"></i> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body">
                          <style>
                              label.col-auto {
                                  min-width: 14ch;
                              }
                          </style>
                          <form>
                              <div class="form-group row mt-2">
                                  <label for="inputEmail3" class="col-auto col-form-label small text-lg-end">ID</label>
                                  <div class="col-lg-3">
                                      <input type="text" value="3929" class="form-control form-control-sm" id="inputEmail3">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputEmail3" class="col-auto col-form-label small text-lg-end">Bezeichnung</label>
                                  <div class="col-lg-7">
                                      <input type="text" value="Spezialist Debitoren / Kreditmanagement" class="form-control form-control-sm" id="inputEmail3">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputPassword3" class="col-auto col-form-label small text-lg-end">Tätigkeitsfeld</label>
                                  <div class="col-lg-7">
                                      <select class="form-control custom-select-sm custom-select">
                                          <option _ngcontent-feg-c257="" value="0: 1001" class="ng-star-inserted">Analyse&nbsp;und&nbsp;Statistik</option>
                                          <option _ngcontent-feg-c257="" value="1: 1002" class="ng-star-inserted">Administration,&nbsp;Sachbearbeitung&nbsp;und&nbsp;Verwaltung</option>
                                          <option _ngcontent-feg-c257="" value="2: 1003" class="ng-star-inserted">Beratung&nbsp;/&nbsp;Consulting</option>
                                          <option _ngcontent-feg-c257="" value="3: 1004" class="ng-star-inserted">Kundenbetreuung</option>
                                          <option _ngcontent-feg-c257="" value="4: 1005" class="ng-star-inserted">Einkauf,&nbsp;Materialwirtschaft&nbsp;und&nbsp;Logistik</option>
                                          <option _ngcontent-feg-c257="" value="5: 1006" class="ng-star-inserted">Finanzen,&nbsp;Rechnungswesen&nbsp;und&nbsp;Controlling</option>
                                          <option _ngcontent-feg-c257="" value="6: 1007" class="ng-star-inserted">Forschung,&nbsp;Lehre&nbsp;und&nbsp;Entwicklung</option>
                                          <option _ngcontent-feg-c257="" value="7: 1008" class="ng-star-inserted">Gesundheit,&nbsp;Medizin&nbsp;und&nbsp;Soziales</option>
                                          <option _ngcontent-feg-c257="" value="8: 1009" class="ng-star-inserted">Grafik,&nbsp;Design&nbsp;und&nbsp;Architektur</option>
                                          <option _ngcontent-feg-c257="" value="9: 1010" class="ng-star-inserted">Ingenieurwesen&nbsp;und&nbsp;technische&nbsp;Berufe</option>
                                          <option _ngcontent-feg-c257="" value="10: 1011" class="ng-star-inserted">IT&nbsp;und&nbsp;Softwareentwicklung</option>
                                          <option _ngcontent-feg-c257="" value="11: 1012" class="ng-star-inserted">Management&nbsp;und&nbsp;Unternehmensentwicklung</option>
                                          <option _ngcontent-feg-c257="" value="12: 1013" class="ng-star-inserted">Marketing&nbsp;und&nbsp;Werbung</option>
                                          <option _ngcontent-feg-c257="" value="13: 1014" class="ng-star-inserted">Personalwesen&nbsp;und&nbsp;HR</option>
                                          <option _ngcontent-feg-c257="" value="14: 1015" class="ng-star-inserted">PR,&nbsp;Öffentlichkeitsarbeit&nbsp;und&nbsp;Journalismus</option>
                                          <option _ngcontent-feg-c257="" value="15: 1016" class="ng-star-inserted">Produktion&nbsp;und&nbsp;Handwerk</option>
                                          <option _ngcontent-feg-c257="" value="16: 1017" class="ng-star-inserted">Produktmanagement</option>
                                          <option _ngcontent-feg-c257="" value="17: 1018" class="ng-star-inserted">Projektmanagement</option>
                                          <option _ngcontent-feg-c257="" value="18: 1019" class="ng-star-inserted">Prozessplanung&nbsp;und&nbsp;Qualitätssicherung</option>
                                          <option _ngcontent-feg-c257="" value="19: 1020" class="ng-star-inserted">Recht</option>
                                          <option _ngcontent-feg-c257="" value="20: 1021" class="ng-star-inserted">Vertrieb&nbsp;und&nbsp;Handel</option>
                                          <option _ngcontent-feg-c257="" value="21: 1022" class="ng-star-inserted">Sonstige&nbsp;Tätigkeitsfelder</option>
                                          <!---->
                                      </select>
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputPassword3" class="col-auto col-form-label small text-lg-end">Tags</label>
                                  <div class="col-lg-7">
                                      <input type="text" placeholder="#hashtags" class="form-control form-control-sm" id="inputEmail3">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputPassword3" class="col-auto col-form-label col-form-label-sm small text-lg-end">Kunde</label>
                                  <div class="col-lg-7 input-group">
                                      <div class="form-control form-control-sm">
                                          <a _ngcontent-feg-c255="" href="/companies/5406" class="ng-star-inserted">DFS Deutsche Flugsicherung GmbH</a>
                                      </div>
                                      <div class="input-group-append">
                                          <button class="btn btn-secondary btn-sm" type="button" id="button-addon2"><svg class="svg-inline--fa fa-pen" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"></path></svg><!-- <i class="fa-solid fa-pen"></i> --></button>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputPassword3" class="col-auto col-form-label small text-lg-end">Bestellnr.</label>
                                  <div class="col-lg-7">
                                      <input type="text" class="form-control form-control-sm" id="inputEmail3">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="inputPassword3" class="col-auto col-form-label small text-lg-end">Erstellt</label>
                                  <div class="col-lg-3">
                                      <input type="date" value="2017-06-01" class="form-control form-control-sm">
                                  </div>
                              </div>
                          </form>
                          <div>
                          </div>
                      </div>
                  </div>
              </div>
  </div></div></section>
  <div class="teaser-card card mb-5">
      <a href="#
">
          <div class="img-container hover-zoom">
              <img class="card-img-top" src="assets/img/cards/card-img-top.jpg">
          </div>
      </a>
      <div class="card-body">
          <div class="row align-items-center">
              <div class="col-12 ">
                  <h3 class="mb-1"> Data Scientist <span class="small suffix">m|w|d</span></h3>
                  <span class="mt-2 mb-1 ml-n1 badge badge-light badge-pill badge-sm">Ansprache läuft</span>
              </div>
          </div>
      </div>
      <div class="card-body py-0">
          <hr class="my-0 ">
      </div>
      <div class="card-body">
          <div class="row">
              <div class="col-12">
                  <div class=" feed-comment">
                      <div class="feed-header  has-desc ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/lars-branscheid.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Lars Branscheid
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              am 05.07.21 um 11:51 Uhr
                                          </span>
                                      </div>
                                      <p class="mb-0 desc">hat <a href="">
                                              <strong>Soufian Khaie</strong></a>
                                          abgelehnt
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class=" feed-comment">
                      <div class="feed-header  has-desc ">
                          <div class="avatar-user-item">
                              <div class="row align-items-center">
                                  <div class="col-auto">
                                      <div class=" avatar avatar-sm">
                                          <img src="../assets/img/avatars/profiles/patrick-eschler.jpg" alt="..." class="avatar-img">
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <div>
                                          <h6 class="mb-0 d-inline-block">
                                              Patrick Eschler
                                          </h6>
                                          <span class="smaller d-inline-block text-muted mb-0">
                                              am 05.07.21 um 11:51 Uhr
                                          </span>
                                      </div>
                                      <p class="mb-0 desc">hat <a href="#"><strong>Christin Bartelt</strong></a> für ein Kennenlernen ausgewählt
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card-footer ">
          <div class="row align-items-center justify-content-end">
              <div class="col-auto">
                  <a href="#" class="link-arrow right">Projekt
                      ansehen</a>
              </div>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class=" doc-html">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class="card" style="width: 30rem;">
                      <div class="card-body">
                          <h4 class="card-title">Sebastian Ostrowitzki</h4>
                          <p><svg class="svg-inline--fa fa-calendar-clock me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H432 0V464c0 26.5 21.5 48 48 48H330.8C285.6 480.1 256 427.5 256 368c0-97.2 78.8-176 176-176c5.4 0 10.7 .2 16 .7V192zM576 368a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM432 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H432c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z"></path></svg><!-- <i class="fa-solid fa-calendar-clock me-2"></i> --> Mi 30.01.2023, 12:00 Uhr</p>
                          <p class="text-danger">1 Tag überfällig</p>
                          <p>Bitte erstelle die Erfolgsprognose für das angefragte Projekt.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</main>