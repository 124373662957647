import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';

function chartOptions() {
  // Global
  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;

  // Default
  Chart.defaults.color = getCSSVariableValue('--bs-chart-default-color');
  Chart.defaults.font.family = fonts.base;
  Chart.defaults.font.size = 12;

  // Layout
  Chart.defaults.layout.padding = 0;

  // Point
  Chart.defaults.elements.point.radius = 4;
  Chart.defaults.elements.point.backgroundColor = colors.primary[700];

  // Line
  Chart.defaults.elements.line.borderWidth = 3;
  Chart.defaults.elements.line.borderColor = colors.primary[700];
  Chart.defaults.elements.line.backgroundColor = colors.transparent;

  // Rectangle
  Chart.defaults.elements.line.borderWidth = 3;
  Chart.defaults.elements.bar.backgroundColor = colors.primary[700];
  Chart.defaults.elements.bar.borderColor = colors.primary[700];
  Chart.defaults.elements.bar.borderRadius = 5;

  // Arc
  Chart.defaults.elements.arc.backgroundColor = colors.primary[700];
  Chart.defaults.elements.arc.borderColor = getCSSVariableValue('--bs-chart-arc-border-color');
  Chart.defaults.elements.arc.borderWidth = 4;
  Chart.defaults.elements.arc.hoverBorderColor = getCSSVariableValue('--bs-chart-arc-hover-border-color');

}

const colors = {
  primary: {
    700: '#0062D6'
  },
  black: getCSSVariableValue('--bs-dark'),
  white: getCSSVariableValue('--bs-white'),
  transparent: 'transparent',
};

const fonts = {
  base: 'Circular Std'
};


function getCSSVariableValue(variable) {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    chartOptions();
  }

  title = 'sc';
}
