<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Alerts</h1>
          <p class="lead">Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
          </p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Types</h2>
          <p>Add <code>.alert .alert-secondary</code>, <code>.alert .alert-primary</code>, <code>.alert .alert-success</code>, <code>.alert .alert-error</code> .</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <div class="alert alert-secondary">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div class="alert alert-primary">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div class="alert alert-success alert-dismissible">
                  <button type="button" class="close">
                      <span>×</span>
                  </button>
                  <h5 class="alert-heading">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, <a href="#" class="alert-link">an example link</a> consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div class="alert alert-warning">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
              <div class="alert alert-error">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'alerts/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>