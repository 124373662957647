<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Palette</h1>
          <p class="lead">The palette for skillconomy has the following colors
          </p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Black and white</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-black">
              </div>
              <code>$black</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-white">
              </div>
              <code>$white</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Primary</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-primary-darker">
              </div>
              <code>$primary-darker</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-primary-dark">
              </div>
              <code>$primary-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-primary-base">
              </div>
              <code>$primary-base</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-primary-light">
              </div>
              <code>$primary-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-primary-lighter">
              </div>
              <code>$primary-lighter</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Neutral</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-darker">
              </div>
              <code>$neutral-darker</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-dark">
              </div>
              <code>$neutral-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-base">
              </div>
              <code>$neutral-base</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-mid">
              </div>
              <code>$neutral-mid</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-light">
              </div>
              <code>$neutral-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-lighter">
              </div>
              <code>$neutral-lighter</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-neutral-lightest">
              </div>
              <code>$neutral-lightest</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Positive</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-darker">
              </div>
              <code>$positive-darker</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-dark">
              </div>
              <code>$positive-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-base">
              </div>
              <code>$positive-base</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-mid">
              </div>
              <code>$positive-mid</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-light">
              </div>
              <code>$positive-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-lighter">
              </div>
              <code>$positive-lighter</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-positive-lightest">
              </div>
              <code>$positive-lightest</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Negative</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-darker">
              </div>
              <code>$negative-darker</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-dark">
              </div>
              <code>$negative-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-base">
              </div>
              <code>$negative-base</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-mid">
              </div>
              <code>$negative-mid</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-light">
              </div>
              <code>$negative-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-lighter">
              </div>
              <code>$negative-lighter</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-negative-lightest">
              </div>
              <code>$negative-lightest</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Yellow</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-darker">
              </div>
              <code>$yellow-darker</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-dark">
              </div>
              <code>$yellow-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-base">
              </div>
              <code>$yellow-base</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-mid">
              </div>
              <code>$yellow-mid</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-light">
              </div>
              <code>$yellow-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-lighter">
              </div>
              <code>$yellow-lighter</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-yellow-lightest">
              </div>
              <code>$yellow-lightest</code>
          </div>
      </div>
  </div>
  <div class=" doc-color-palette mb-5">
      <div class="row">
          <div class="col-12">
              <h5>Pastel</h5>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-blue">
              </div>
              <code>$pastel-blue</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-petrol">
              </div>
              <code>$pastel-petrol</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-mint-light">
              </div>
              <code>$pastel-mint-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-mint">
              </div>
              <code>$pastel-mint</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-mint-dark">
              </div>
              <code>$pastel-mint-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-green">
              </div>
              <code>$pastel-green</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-olive">
              </div>
              <code>$pastel-olive</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-yellow">
              </div>
              <code>$pastel-yellow</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-lime">
              </div>
              <code>$pastel-lime</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-yellow-light">
              </div>
              <code>$pastel-yellow-light</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-brown">
              </div>
              <code>$pastel-brown</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-rose">
              </div>
              <code>$pastel-rose</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-rose-dark">
              </div>
              <code>$pastel-rose-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-orange-dark">
              </div>
              <code>$pastel-orange-dark</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-red">
              </div>
              <code>$pastel-red</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-orange">
              </div>
              <code>$pastel-orange</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-violet">
              </div>
              <code>$pastel-violet</code>
          </div>
          <div class="col-auto mb-4">
              <div class="color-swatch bg-pastel-purple">
              </div>
              <code>$pastel-purple</code>
          </div>
      </div>
  </div>
</section>