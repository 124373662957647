<div class="doc-hero">
  <div class="row">
    <div class="col">
      <h1 class="huge">Forms</h1>
      <p class="lead">
        Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.
      </p>
    </div>
  </div>
</div>

<section class="doc-section">

  <div class="row">

    <div class="sc-example mb-4 col-12">
      <h2>Inputs and textareas</h2>
      <p>Textual form controls—like <code>&lt;input&gt;</code>s and <code>&lt;textarea&gt;</code>s—are styled with the <code>.form-control</code> class. Included are styles for general appearance, focus state, sizing, and more.
      </p>
    </div>

  </div>

  <div class="doc-html">
    <div class="row">

      <div class="col-12 mb-5">
        <h5>Default</h5>
        <sc-form form="default">
          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>
          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

      <div class="col-12 mb-5">
        <h5>Small</h5>
        <sc-form form="default" size="sm">
          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>
          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

      <div class="col-12 mb-5">
        <h5>Large</h5>
        <sc-form form="default" size="lg">
          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>
          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

    </div>
  </div>
</section>

<section class="doc-section">

  <div class="row">

    <div class="sc-example mb-4 col-12">
      <h2>Horzizontal form</h2>
    </div>

  </div>

  <div class="doc-html">

    <div class="row">

      <div class="col-12 mb-5">

        <h5>Default</h5>
        <sc-form>

          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>

          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

      <div class="col-12 mb-5">
        <h5>Small</h5>
        <sc-form size="sm">
          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>

          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

      <div class="col-12 mb-5">

        <h5>Large</h5>

        <sc-form size="lg">
          <sc-form-row label="Text Input">
            <input />
          </sc-form-row>
          <sc-form-row label="Text Area">
            <textarea></textarea>
          </sc-form-row>

          <sc-form-row label="Select">
            <select>
              <option>Option A</option>
              <option>Option B</option>
              <option>Option C</option>
            </select>
          </sc-form-row>
        </sc-form>
      </div>

    </div>
  </div>

</section>

<section class="doc-section">

  <div class="row">

    <div class="sc-example mb-4 col-12">
      <h2>Checkboxes and radios</h2>
      <p>Custom styled checkboxes and radios</p>
    </div>

  </div>

  <div class="doc-html">

    <div class="row">

      <div class="col-12 mb-5">
        <sc-form form="default">
          <sc-form-row>
            <sc-checkbox></sc-checkbox>
          </sc-form-row>
          <sc-form-row>
            <sc-radio></sc-radio>
          </sc-form-row>
        </sc-form>
      </div>

    </div>

  </div>

		<div class="doc-html">

			<div class="row">

				<div class="col-12 mb-5">

          <h5>Default</h5>
					<sc-form>

						<sc-form-row label="Text Input">
							<input />
						</sc-form-row>
						<sc-form-row label="Text Area">
							<textarea></textarea>
						</sc-form-row>

						<sc-form-row label="Select">							
							<select>
								<option>Option A</option>
								<option>Option B</option>
								<option>Option C</option>
							</select>
						</sc-form-row>
					</sc-form>
				</div>

				<div class="col-12 mb-5">
          <h5>Small</h5>
					<sc-form size="sm">
						<sc-form-row label="Text Input">
							<input />
						</sc-form-row>
						<sc-form-row label="Text Area">
							<textarea></textarea>
						</sc-form-row>
 
						<sc-form-row label="Select">							
							<select>
								<option>Option A</option>
								<option>Option B</option>
								<option>Option C</option>
							</select>
						</sc-form-row>
					</sc-form>
				</div>

        <div class="col-12 mb-5">
          
          <h5>Large</h5>

					<sc-form size="lg">
						<sc-form-row label="Text Input">
							<input />
						</sc-form-row>
						<sc-form-row label="Text Area">
							<textarea></textarea>
						</sc-form-row>
 
						<sc-form-row label="Select">							
							<select>
								<option>Option A</option>
								<option>Option B</option>
								<option>Option C</option>
							</select>
						</sc-form-row>
					</sc-form>
				</div>


      </div>
    </div>

  </section>


  <section class="doc-section">


    <div class="row">

      <div class="sc-example mb-4 col-12">
        <h2>Checkboxes and radios</h2>
        <p>Custom styled checkboxes and radios</p>
      </div>

    </div>



		<div class="doc-html">

			<div class="row">
 
        <div class="col-12 mb-5">
          <sc-form form="default">
            <sc-form-row>
              <sc-checkbox></sc-checkbox>
            </sc-form-row>
            <sc-form-row>
              <sc-radio></sc-radio>
            </sc-form-row>
            
          </sc-form>
        </div>
        
      </div>

    </div>
    
		<div class="doc-html">

			<div class="row mt-5">
 
        <div class="col-12">

          <div class="custom-file mt-5">
            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
            <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
            <div class="invalid-feedback">Example invalid custom file feedback</div>
          </div>

        </div>

      </div>
    </div>
  </section>

