  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Progress</h1>
              <p class="lead">Documentation for adding custom progress bars
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class=" doc-html">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class="progress mb-4">
                      <div class="progress-bar" role="progressbar"></div>
                  </div>
                  <div class="progress mb-4">
                      <div class="progress-bar" role="progressbar" style="width: 25%"></div>
                  </div>
                  <div class="progress mb-4">
                      <div class="progress-bar" role="progressbar" style="width: 50%"></div>
                  </div>
                  <div class="progress mb-4">
                      <div class="progress-bar" role="progressbar" style="width: 75%"></div>
                  </div>
                  <div class="progress mb-4">
                      <div class="progress-bar" role="progressbar" style="width: 100%"></div>
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'progress/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
