import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'sc-chatbot-input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss']
})
export class InputRangeComponent implements OnChanges {

  @Input()
  message: any;

  @Output()
  submit: EventEmitter<any> = new EventEmitter();

  selectedMin: number = 10;

  selectedMax: number = 90;

  selectionType = 'MinMax';
  
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 10,
    showTicks: true
  };

  ngOnChanges() {

    if (this.message?.Payload)
    {
      this.selectedMin = this.message.Payload.SelectedMin;
      this.selectedMax = this.message.Payload.SelectedMax;
      this.selectionType = this.message.Payload.SelectionType;

      this.options = {
        floor: this.message.Payload.Min,
        ceil: this.message.Payload.Max,
        step: this.message.Payload.Step,
        showTicks: this.message.Payload.ShowTicks,
        hideLimitLabels: true
      };
    }

  }

  send() {

    console.log('sending text');

    var message = {
      Direction: "UserToBot",
      Type: 'Range',
      Payload: {      
        SelectedMin:  this.selectedMin,
        Body: this.selectedMax
      }
    }; 

    this.submit.next(message);
  }


}
