 
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Avatars</h1>
              <p class="lead">Create and group avatars of different sizes and shapes with a single component.
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Sizes</h2>
              <p>Using Bootstrap’s typical naming structure, you can create a standard avatar, or scale it up to different sizes based on what’s needed.</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar avatar-xxl mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar avatar-xl mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar avatar-lg mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar avatar-sm mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar avatar-xs mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'avatars/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Status</h2>
              <p>Add <code>.avatar-online</code> or <code>.avatar-offline</code></p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar avatar-online mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
                  <div class="avatar avatar-offline mx-1">
                      <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'avatars/code-2.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Initials</h2>
              <p>You won't always have an image for every user, so easily use initials instead.</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar avatar-xxl  mx-1">
                      <span class="avatar-title bg-pastel-lime">MB</span>
                  </div>
                  <div class="avatar avatar-xl mx-1">
                      <span class="avatar-title bg-pastel-yellow">MB</span>
                  </div>
                  <div class="avatar avatar-lg mx-1">
                      <span class="avatar-title bg-pastel-rose-dark">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-mint-light">MB</span>
                  </div>
                  <div class="avatar avatar-sm mx-1">
                      <span class="avatar-title bg-pastel-red">MB</span>
                  </div>
                  <div class="avatar avatar-xs mx-1">
                      <span class="avatar-title bg-pastel-mint-light">MB</span>
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'avatars/code-3.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Initials colors</h2>
              <p>Add <code>.bg-color</code> class</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-blue bg-pastel-rose-dark">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-petrol bg-pastel-blue">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-mint-light bg-pastel-red">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-mint bg-pastel-olive">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-mint-dark bg-pastel-orange">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-green bg-pastel-yellow">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-olive">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-yellow bg-pastel-rose-dark">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-lime bg-pastel-violet">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-yellow-light bg-pastel-petrol">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-brown bg-pastel-mint-dark">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-rose bg-pastel-olive">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-rose-dark bg-pastel-petrol">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-orange-dark bg-pastel-mint-light">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-red bg-pastel-rose">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-orange bg-pastel-red">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-violet bg-pastel-yellow">MB</span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-title bg-pastel-purple bg-pastel-brown">MB</span>
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'avatars/code-4.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Groups</h2>
              <p>Easily group avatars of any size, shape and content with a single component. Each avatar can also use an to link to the corresponding profile.</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar-group">
                      <div class="avatar avatar-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Lena Wagner">
                          <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                      </div>
                      <div class="avatar avatar-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Lena Wagner">
                          <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                      </div>
                      <div class="avatar avatar-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Lena Wagner">
                          <img src="../assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img">
                      </div>
                      <div class="avatar avatar-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Carsten Friedrich">
                          <span class="avatar-title bg-pastel-yellow">CF</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'avatars/code-5.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Avatar user item</h2>
              <p></p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-12 mb-4">
                  <div class="avatar-user-item">
                      <div class="row align-items-center">
                          <div class="col-auto">
                              <div class="avatar avatar-sm">
                                  <span class="avatar-title bg-pastel-purple bg-pastel-petrol">CF</span>
                              </div>
                          </div>
                          <div class="col-5 ms-n2">
                              <h6 class="mb-0">
                                  Patrick Eschler
                              </h6>
                              <p class="small text-muted mb-0">
                                  patrick@skillconomy.com
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <div class="avatar-user-item">
                      <div class="row align-items-center">
                          <div class="col-auto">
                              <div class="avatar avatar-sm">
                                  <span class="avatar-title bg-pastel-violet bg-pastel-green">PE</span>
                              </div>
                          </div>
                          <div class="col-5 ms-n2">
                              <h6 class="mb-0 d-inline-block">Patrick Eschler</h6>
                              <span class="small d-inline-block text-muted mb-0">am 04.11.22 um 08:35 Uhr</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Icons</h2>
              <p>Avatars with icons.</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col mb-4">
                  <div class="avatar avatar-xxl mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n2"></i> -->
                      </span>
                  </div>
                  <div class="avatar avatar-xl mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n2"></i> -->
                      </span>
                  </div>
                  <div class="avatar avatar-lg mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n2"></i> -->
                      </span>
                  </div>
                  <div class="avatar mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                      </span>
                  </div>
                  <div class="avatar avatar-sm mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                      </span>
                  </div>
                  <div class="avatar avatar-xs mx-1">
                      <span class="avatar-icon">
                          <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </section>
