<div class=" doc-hero">
  <div class="row">
    <div class="col">
      <h1 class="huge">Chatbot</h1>
      <p class="lead">Chatbot framework with different question and answer types.
      </p>
    </div>
  </div>
</div>

<section class=" doc-section">
  <div class="container">
    <div class="row mb-3 mb-n2">
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(choiceMessage)">Einfachauswahl</button>
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(choiceMessageMultiple)">Mehrfachauswahl</button>
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(yesNoMessage)">Ja/Nein</button>
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(textMessageSingleLine)">Text einzeilig</button>
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(textMessageMultiline)">Text mehrzeilig</button>
      <button class="btn btn-dark me-2 mb-2" (click)="selectMessage(rangeMessage)">Range</button>
    </div>
    <div class="row mt-3">
      <div class="col-12" *ngIf="message && chat">
        <div class="card">
          <div class="card-body">
            <sc-chatbot-input #choiceInput [message]="message" [chat]="chat" (submit)="onSubmit($event, choiceInput)"></sc-chatbot-input>

          </div>
        </div>
      </div>
    </div>
  </div>

</section>

<sc-chatbot-overlay #chatbotOverlay [chat]="chat"></sc-chatbot-overlay>
