import { Component, OnInit, ViewChild } from '@angular/core';
import { ChatsService, ScChatbotInputComponent, ScChatbotOverlayComponent } from '@sc-ui-chatbot';

import * as _ from 'lodash';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {

  chat: any = null;

  @ViewChild(ScChatbotOverlayComponent)
  chatOverlay: ScChatbotOverlayComponent;

  selectedMessage: any = {};

  message: any = {};

  choiceMessage = {
    Payload: {
      Body: "Bitte wähle aus, welchen Fragentyp du anzeigen möchtest:",
      Label: null,
      ContainerCssClass: null,
      ContentCssClass: null,
      MinimumSelectedChoices: 1,
      MaximumSelectedChoices: 1,
      Choices: [
        {
          Label: "Auswahl",
          Value: "0"
        },
        {
          Label: "Auswahl (viele Optionen, einfach)",
          Value: "1"
        },
        {
          Label: "Auswahl (viele Optionen, mehrfach)",
          Value: "2"
        },
        {
          Label: "Ja/Nein",
          Value: "3"
        },
        {
          Label: "Text",
          Value: "4"
        },
        {
          Label: "Upload",
          Value: "5"
        },
        {
          Label: "Map",
          Value: "6"
        }
      ]
    },
    Type: "Choice",
    Timestamp: "2023-08-30T12:16:02.8906371Z",
    StepId: "98d08d35-a331-c252-f938-9e2b8135ba52",
    Direction: "BotToUser"
  };

  choiceMessageMultiple: any = {
    Payload: {
      Body: "Bitte wähle aus, welchen Fragentyp du anzeigen möchtest:",
      Label: null,
      ContainerCssClass: null,
      ContentCssClass: null,
      MinimumSelectedChoices: 1,
      MaximumSelectedChoices: 100,
      Choices: [
        {
          Label: "Auswahl",
          Value: "0"
        },
        {
          Label: "Auswahl (viele Optionen, einfach)",
          Value: "1"
        },
        {
          Label: "Auswahl (viele Optionen, mehrfach)",
          Value: "2"
        },
        {
          Label: "Ja/Nein",
          Value: "3"
        },
        {
          Label: "Text",
          Value: "4"
        },
        {
          Label: "Upload",
          Value: "5"
        },
        {
          Label: "Map",
          Value: "6"
        }
      ]
    },
    Type: "Choice",
    Timestamp: "2023-08-30T12:16:02.8906371Z",
    StepId: "98d08d35-a331-c252-f938-9e2b8135ba52",
    Direction: "BotToUser"
  };

  yesNoMessage: any = {
    "Id": "95d04e41-6788-4074-a962-08dba942a4c1",
    "Index": 3,
    "Payload": {
      "Body": "Was ist deine Antwort?",
      "ContainerCssClass": null,
      "ContentCssClass": null,
      "YesLabel": "Ja",
      "NoLabel": "Nein",
      "NoCssClass": "btn-outline-primary",
      "NoFaIconType": null,
      "NoFaIconName": null,
      "YesFaIconType": null,
      "YesFaIconName": null,
      "YesCssClass": "btn-outline-primary"
    },
    "Type": "YesNo",
    "TrackingLabel": null,
    "Timestamp": "2023-08-30T12:52:52.3621005Z",
    "StepId": "47fe2039-0935-bfc6-6571-afc3a5616240",
    "Direction": "BotToUser",
    "SenderId": null,
    "Sender": null
  };

  textMessageMultiline: any =
    {
      "Id": "bfb2215a-7d83-4c3b-a966-08dba942a4c1",
      "Index": 7,
      "Payload": {
        "Body": "Was ist dein Text?",
        "ContainerCssClass": null,
        "ContentCssClass": null,
        "Placeholder": null,
        "IsMultiline": true,
        "IsPassword": false,
        "ValidationRegularExpression": null,
        "ValidationErrorMessage": null,
        "ValidateIsPhone": false,
        "ValidateIsEMail": false
      },
      "Type": "Text",
      "TrackingLabel": null,
      "Timestamp": "2023-08-30T12:54:37.2567533Z",
      "StepId": "05d0b431-9db5-0ca2-725d-06a0367de249",
      "Direction": "BotToUser",
      "SenderId": null,
      "Sender": null
    };

  textMessageSingleLine: any =
    {
      "Id": "bfb2215a-7d83-4c3b-a966-08dba942a4c1",
      "Index": 7,
      "Payload": {
        "Body": "Was ist dein Text?",
        "ContainerCssClass": null,
        "ContentCssClass": null,
        "Placeholder": null,
        "IsMultiline": false,
        "IsPassword": false,
        "ValidationRegularExpression": null,
        "ValidationErrorMessage": null,
        "ValidateIsPhone": false,
        "ValidateIsEMail": false
      },
      "Type": "Text",
      "TrackingLabel": null,
      "Timestamp": "2023-08-30T12:54:37.2567533Z",
      "StepId": "05d0b431-9db5-0ca2-725d-06a0367de249",
      "Direction": "BotToUser",
      "SenderId": null,
      "Sender": null
    };

  rangeMessage: any = {
    Payload: {
      Body: "Bitte gib Minimum und Maximum an:",
      SelectionType: "Single",
      Min: 0,
      SelectedMin: 20,
      Max: 90,
      SelectedMax: 30,
      Step: 10,
      ShowTicks: true,
    },
    Type: "Range",
    Timestamp: "2023-08-30T12:16:02.8906371Z",
    StepId: "98d08d35-a331-c252-f938-9e2b8135ba52",
    Direction: "BotToUser"
  };


  constructor(private chatService: ChatsService) {
    this.choiceMessageMultiple.Payload.MaximumSelectedChoices = 100;
    this.selectMessage(this.rangeMessage);
  }

  ngOnInit(): void {

    this.chatService.getChat('chats').subscribe((c: any) => {
      this.chat = c;
      if (this.chatOverlay?.trigger) {
        this.chatOverlay.trigger.slideIn();
        this.chatOverlay.trigger.badge = 1;
      }
    });
    console.log(this.choiceMessage);
  }

  selectMessage(message) {
    this.message = _.cloneDeep(message);
    this.selectedMessage = message;
  }

  onSubmit(args, component: ScChatbotInputComponent) {
    setTimeout(() => {
      component.lock = false;
      this.message = _.cloneDeep(this.selectedMessage);
    }, 1000);
  }

}
