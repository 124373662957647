import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './views/start/start.component';
import { TypographyComponent } from './views/typography/typography.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { PaletteComponent } from './views/palette/palette.component';
import { ButtonsComponent } from './views/buttons/buttons.component';
import { BordersComponent } from './views/borders/borders.component';
import { BoxShadowsComponent } from './views/box-shadows/box-shadows.component';
import { SpacingComponent } from './views/spacing/spacing.component';
import { FormsComponent } from './views/forms/forms.component';
import { AvatarsComponent } from './views/avatars/avatars.component';
import { BadgesComponent } from './views/badges/badges.component';
import { TooltipsComponent } from './views/tooltips/tooltips.component';
import { ProgressComponent } from './views/progress/progress.component';
import { ModalComponent } from './views/modal/modal.component';
import { CardsComponent } from './views/cards/cards.component';
import { FeedComponent } from './views/feed/feed.component';
import { ListGroupComponent } from './views/list-group/list-group.component';
import { ChatbotComponent } from './views/chatbot/chatbot.component';
import { ConfettiComponent } from './views/confetti/confetti.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/start',
        pathMatch: 'full'
      },
      {
        path: 'start',
        component: StartComponent
      },
      {
        path: 'typography',
        component: TypographyComponent
      },
      {
        path: 'palette',
        component: PaletteComponent
      },
      {
        path: 'buttons',
        component: ButtonsComponent
      },
      {
        path: 'borders',
        component: BordersComponent
      },
      {
        path: 'box-shadows',
        component: BoxShadowsComponent
      },
      {
        path: 'spacing',
        component: SpacingComponent
      },
      {
        path: 'alerts',
        component: AlertsComponent
      },
      {
        path: 'forms',
        component: FormsComponent
      },
      {
        path: 'avatars',
        component: AvatarsComponent
      },
      {
        path: 'badges',
        component: BadgesComponent
      },
      {
        path: 'tooltips',
        component: TooltipsComponent
      },
      {
        path: 'progress',
        component: ProgressComponent
      },
      {
        path: 'modal',
        component: ModalComponent
      },
      {
        path: 'cards',
        component: CardsComponent
      },
      {
        path: 'feed',
        component: FeedComponent
      },
      {
        path: 'list-group',
        component: ListGroupComponent
      },
      {
        path: 'chatbot',
        component: ChatbotComponent
      },
      {
        path: 'confetti',
        component: ConfettiComponent
      }

    ]
  }  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
