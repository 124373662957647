 
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Spacing</h1>
              <p class="lead">A wide range of shorthand responsive margin, padding, and gap utility classes to modify an element’s appearance</p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Spacer unit</h2>
              <p>
                  Spacing classes are built from a
                  default spacer unit with 4px size.
              </p>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'spacing/code-1.example.html' | codeSample | async" [languages]="['scss']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Notation</h2>
              <p>Spacing utilities that apply to all breakpoints, from <code>xs</code> to <code>xxl</code>,
                  have no breakpoint abbreviation in them. This is because those classes are applied from
                  <code>min-width: 0</code> and up, and thus are not bound by a media query. The remaining
                  breakpoints, however, do include a breakpoint abbreviation.
              </p>
              <p>The classes are named using the format <code>{{'{'}}property{{'}'}}{{'{'}}sides{{'}'}}-{{'{'}}size{{'}'}}</code> for <code>xs</code> and <code>{{'{'}}property{{'}'}}{{'{'}}sides{{'}'}}-{{'{'}}breakpoint{{'}'}}-{{'{'}}size{{'}'}}</code> for <code>sm</code>, <code>md</code>, <code>lg</code>, <code>xl</code>, and <code>xxl</code>.</p>
              <p>Where <em>property</em> is one of:</p>
              <ul>
                  <li><code>m</code> - for classes that set <code>margin</code></li>
                  <li><code>p</code> - for classes that set <code>padding</code></li>
              </ul>
              <p>Where <em>sides</em> is one of:</p>
              <ul>
                  <li><code>t</code> - for classes that set <code>margin-top</code> or <code>padding-top</code></li>
                  <li><code>b</code> - for classes that set <code>margin-bottom</code> or <code>padding-bottom</code></li>
                  <li><code>s</code> - (start) for classes that set <code>margin-left</code> or <code>padding-left</code></li>
                  <li><code>e</code> - (end) for classes that set <code>margin-right</code> or <code>padding-right</code></li>
                  <li><code>x</code> - for classes that set both <code>*-left</code> and <code>*-right</code></li>
                  <li><code>y</code> - for classes that set both <code>*-top</code> and <code>*-bottom</code></li>
                  <li>blank - for classes that set a <code>margin</code> or <code>padding</code> on all 4 sides of the element</li>
              </ul>
              <p class="mx-auto">Where <em>size</em> is one of:</p>
              <ul>
                  <li><code>0</code> - for classes that eliminate the <code>margin</code> or <code>padding</code> by setting it to <code>0</code></li>
                  <li><code>1</code> - (by default) for classes that set the <code>margin</code> or <code>padding</code> to <code>$spacer * 1</code></li>
                  <li><code>2</code> - (by default) for classes that set the <code>margin</code> or <code>padding</code> to <code>$spacer * 2</code></li>
                  <li><code>3</code> - (by default) for classes that set the <code>margin</code> or <code>padding</code> to <code>$spacer * 4</code></li>
                  <li><code>4</code> - (by default) for classes that set the <code>margin</code> or <code>padding</code> to <code>$spacer * 6</code></li>
                  <li><code>5</code> - (by default) for classes that set the <code>margin</code> or <code>padding</code> to <code>$spacer * 12</code></li>
                  <li><code>auto</code> - for classes that set the <code>margin</code> to auto</li>
              </ul>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'spacing/code-1.example.html' | codeSample | async" [languages]="['scss']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
 