
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">Box Shadows</h1>
              <p class="lead">Add or remove shadows to elements with <code>.box-shadow</code>
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class=" doc-boxes mb-5">
          <div class="row">
              <div class="col-12">
                  <h5>Box Shadows</h5>
              </div>
              <div class="col-12 mb-5">
                  <code>.box-shadow-sm</code>
                  <div class="box mt-4 mb-2 box-shadow-sm">
                  </div>
              </div>
              <div class="col-12 mb-5">
                  <code>.box-shadow</code>
                  <div class="box mt-4 mb-2 box-shadow">
                  </div>
              </div>
              <div class="col-12 mb-5">
                  <code>.box-shadow-lg</code>
                  <div class="box mt-4 mb-2 box-shadow-lg">
                  </div>
              </div>
          </div>
      </div>
  </section>
