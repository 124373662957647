<main>
	<div class="doc-hero">
		<div class="row">
			<div class="col">
				<h1 class="huge">Modals</h1>
				<p class="lead">
					...
				</p>
			</div>
		</div>
	</div>
	<section class="doc-section">
		<div class="doc-html">
			<div class="row">
				<div class="col-12 mb-4">
					<button class="btn btn-primary" (click)="modal.show()">Open modal</button>
				</div>
			</div>
		</div>
	</section>
</main>

<sc-modal #modal>
	<sc-modal-header>
		Modal Title
	</sc-modal-header>
	<sc-modal-body>
		<p>
			Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
		</p>
	</sc-modal-body>
	<sc-modal-footer>
		<button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
	</sc-modal-footer>
</sc-modal>