
  <div class=" doc-hero">
      <div class="row">
          <div class="col">
              <h1 class="huge">List group</h1>
              <p class="lead">Display a series of content.
              </p>
          </div>
      </div>
  </div>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Basic</h2>
              <p>Add <code>.list-group-flush</code> to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-md-5 mb-4">
                  <ul class="list-group">
                      <li class="list-group-item">An item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                      <li class="list-group-item">A fourth item</li>
                      <li class="list-group-item">And a fifth one</li>
                  </ul>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'list-group/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Links and buttons</h2>
              <p>Use <code>&lt;a&gt;</code>s or <code>&lt;button&gt;</code>s to create <em>actionable</em> list group items with hover, disabled, and active states by adding <code>.list-group-item-action</code>. We separate these pseudo-classes to ensure list groups made of non-interactive elements (like <code>&lt;li&gt;</code>s or <code>&lt;div&gt;</code>s) don’t provide a click or tap affordance.</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-md-5 mb-4">
                  <div class="list-group">
                      <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
                          The current link item
                      </a>
                      <a href="#" class="list-group-item list-group-item-action">A second link item</a>
                      <a href="#" class="list-group-item list-group-item-action">A third link item</a>
                      <a href="#" class="list-group-item list-group-item-action">A fourth link item</a>
                      <a class="list-group-item list-group-item-action disabled">A disabled link item</a>
                  </div>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'list-group/code-2.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Flush</h2>
              <p>Add <code>.list-group-flush</code> to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-md-5 mb-4">
                  <ul class="list-group list-group-flush">
                      <li class="list-group-item">An item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                      <li class="list-group-item">A fourth item</li>
                      <li class="list-group-item">And a fifth one</li>
                  </ul>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'list-group/code-3.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>With badges</h2>
              <p>Add badges to any list group item to show unread counts, activity</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-md-5 mb-4">
                  <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                          A list item
                          <span class="badge badge-primary badge-pill">14</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                          A second list item
                          <span class="badge badge-primary badge-pill">2</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                          A third list item
                          <span class="badge badge-primary badge-pill">1</span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class=" doc-example-code">
          <div class="row">
              <div class="col">
                <pre>
                  <code [highlight]="'list-group/code-4.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                  </code>
                </pre>
              </div>
          </div>
      </div>
  </section>
  <section class=" doc-section">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h2>Advanced</h2>
              <p>Add badges to any list group item to show unread counts, activity</p>
          </div>
      </div>
      <div class=" doc-html">
          <div class="row">
              <div class="col-md-7 mb-4">
                  <div class="list-group box-shadow-sm">
                      <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                          <div class="avatar-user-item">
                              <div class="row align-items-center lh-1">
                                  <div class="col-auto">
                                      <div class="avatar avatar-sm">
                                          <span class="avatar-icon">
                                              <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                                          </span>
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <h6 class="mb-0 d-inline-block">Bewerbung prüfen</h6>
                                      <span class="small d-inline-block text-muted mb-0">Eine Kandidat:in hat sich beworben</span>
                                  </div>
                              </div>
                          </div>
                          <span class="badge badge-pill badge-light status-danger">12</span>
                      </a>
                      <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                          <div class="avatar-user-item">
                              <div class="row align-items-center lh-1">
                                  <div class="col-auto">
                                      <div class="avatar avatar-sm">
                                          <span class="avatar-icon">
                                              <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                                          </span>
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <h6 class="mb-0 d-inline-block">Bewerbung prüfen</h6>
                                      <span class="small d-inline-block text-muted mb-0">Eine Kandidat:in hat sich beworben</span>
                                  </div>
                              </div>
                          </div>
                          <span class="badge badge-pill badge-light status-danger">12</span>
                      </a>
                      <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                          <div class="avatar-user-item">
                              <div class="row align-items-center lh-1">
                                  <div class="col-auto">
                                      <div class="avatar avatar-sm">
                                          <span class="avatar-icon">
                                              <svg class="svg-inline--fa fa-user-magnifying-glass me-n1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-.8 0-1.5 0-2.3c-88.4 0-160-71.6-160-160c0-5.1 .2-10.1 .7-15c-6.2-.7-12.6-1-19-1H178.3zM448 240.1a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208c26.7 0 51.4-8.2 71.9-22.1L599 505.1c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-79.1-79.1c14-20.5 22.1-45.3 22.1-71.9c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128z"></path></svg><!-- <i class="fa-solid fa-user-magnifying-glass me-n1"></i> -->
                                          </span>
                                      </div>
                                  </div>
                                  <div class="col ms-n2">
                                      <h6 class="mb-0 d-inline-block">Bewerbung prüfen</h6>
                                      <span class="small d-inline-block text-muted mb-0">Eine Kandidat:in hat sich beworben</span>
                                  </div>
                              </div>
                          </div>
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </section>
