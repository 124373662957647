import { Component } from '@angular/core';
import { ConfettiService } from '@sc-ui';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss']
})
export class ConfettiComponent {

  constructor(private confettiService : ConfettiService) {}

  confetti()
  {
    this.confettiService.start(5);
  }

}
