<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Buttons</h1>
          <p class="lead">Custom button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more
          </p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Button types</h2>
          <p>Add <code>.btn-secondary</code>, <code>.btn-primary</code> or <code>.btn-dark</code> .</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <button class="btn btn-secondary mx-1">Button</button> 
              <button class="btn btn-primary mx-1">Button</button> 
              <button class="btn btn-dark mx-1">Button</button>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'buttons/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
          </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Button round</h2>
          <p>Add <code>.btn-round</code>.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round mx-1">Button</button>
              <button class="btn btn-primary btn-round mx-1">Button</button>
              <button class="btn btn-dark btn-round mx-1">Button</button>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'buttons/code-2.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
          </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Button circle</h2>
          <p>Add <code>.btn-circle</code>.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
        <div class="col-12 mb-4">
          <button class="btn btn-secondary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
          <button class="btn btn-primary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
          <button class="btn btn-dark btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
       </div>

       <div class="col-12 mb-4">
         <button class="btn btn-sm btn-secondary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
         <button class="btn btn-sm btn-primary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
         <button class="btn btn-sm btn-dark btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
       </div>

       <div class="col-12 mb-4">
         <button class="btn btn-lg btn-secondary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
         <button class="btn btn-lg btn-primary btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
         <button class="btn btn-lg btn-dark btn-circle mx-1"><i class="fa-sharp fa-regular fa-plus"></i></button>
       </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'buttons/code-3.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
          </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Button sizes</h2>
          <p>Add <code>.btn-sm</code> or <code>.btn-lg</code>.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-sm mx-1">Button small</button>
              <button class="btn btn-primary btn-sm mx-1">Button small</button>
              <button class="btn btn-dark btn-sm mx-1">Button small</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round btn-sm mx-1">Button small</button>
              <button class="btn btn-primary btn-round btn-sm mx-1">Button small</button>
              <button class="btn btn-dark btn-round btn-sm mx-1">Button small</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-lg mx-1">Button large</button>
              <button class="btn btn-primary btn-lg mx-1">Button large</button>
              <button class="btn btn-dark btn-lg mx-1">Button large</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round btn-lg mx-1">Button large</button>
              <button class="btn btn-primary btn-round btn-lg mx-1">Button large</button>
              <button class="btn btn-dark btn-round btn-lg mx-1">Button large</button>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'buttons/code-4.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
          </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Button states</h2>
          <p>Add <code>.loading</code> or <code>.disabled</code>.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-sm loading mx-1 mx-1">Button small</button>
              <button class="btn btn-primary btn-sm loading mx-1">Button small</button>
              <button class="btn btn-dark btn-sm loading mx-1">Button small</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round btn-sm loading mx-1">Button small</button>
              <button class="btn btn-primary btn-round btn-sm loading mx-1">Button small</button>
              <button class="btn btn-dark btn-round btn-sm loading mx-1">Button small</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary loading mx-1">Button</button>
              <button class="btn btn-primary loading mx-1">Button</button>
              <button class="btn btn-dark loading mx-1">Button</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round loading mx-1">Button</button>
              <button class="btn btn-primary btn-round loading mx-1">Button</button>
              <button class="btn btn-dark btn-round loading mx-1">Button</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-lg loading mx-1">Button large</button>
              <button class="btn btn-primary btn-lg loading mx-1">Button large</button>
              <button class="btn btn-dark btn-lg loading mx-1">Button large</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary btn-round btn-lg loading mx-1">Button large</button>
              <button class="btn btn-primary btn-round btn-lg loading mx-1">Button large</button>
              <button class="btn btn-dark btn-round btn-lg loading mx-1">Button large</button>
          </div>
          <div class="col-12 mb-4">
              <button class="btn btn-secondary disabled mx-1">Button</button>
              <button class="btn btn-primary disabled mx-1">Button</button>
              <button class="btn btn-dark disabled mx-1">Button</button>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">

            <pre>
              <code [highlight]="'buttons/code-5.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>

          </div>
      </div>
  </div>
</section>