<div class=" doc-hero">
	<div class="row">
	  <div class="col">
		<h1 class="huge">Konfetti</h1>
		<p class="lead">...
		</p>
	  </div>
	</div>
  </div>
  
  <section class=" doc-section">
	<div class="container">
	  <div class="row mb-3 mb-n2">
		<button class="btn btn-dark me-2 mb-2" (click)="confetti()">Konfetti</button>
	  </div>
	</div>
  
  </section>

  <sc-confetti></sc-confetti>