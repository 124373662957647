


<aside>
  <ul class="list-unstyled">
    <li><a routerLink="typography">Typography</a></li>
    <li><a routerLink="palette">Palette</a></li>
    <li><a routerLink="buttons">Buttons</a></li>
    <li><a routerLink="borders">Borders</a></li>
    <li><a routerLink="box-shadows">Box Shadows</a></li>
    <li><a routerLink="spacing">Spacing</a></li>
    <li><a routerLink="alerts">Alerts</a></li>
    <li><a routerLink="forms">Forms</a></li>
    <li><a routerLink="avatars">Avatars</a></li>
    <li><a routerLink="badges">Badges</a></li>
    <li><a routerLink="tooltips">Tooltips</a></li>
    <li><a routerLink="progress">Progress</a></li>
    <li><a routerLink="modal">Modal</a></li>
    <li><a routerLink="cards">Cards</a></li>
    <li><a routerLink="feed">Feed</a></li>
    <li><a routerLink="list-group">List group</a></li>
    <li><a routerLink="chatbot">Chatbot</a></li>
    <li><a routerLink="confetti">Konfetti</a></li>
  </ul>
</aside>
