<app-topbar></app-topbar>

<div class="container">

  <div class="row">

    <div class="col-lg-2">

      <app-sidebar></app-sidebar>

    </div>

    <div class="col-lg-7">

      <main>
        <router-outlet></router-outlet>
      </main>
      
    </div>



  </div>

</div>