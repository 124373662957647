import { Component } from '@angular/core';

@Component({
  selector: 'app-box-shadows',
  templateUrl: './box-shadows.component.html',
  styleUrls: ['./box-shadows.component.scss']
})
export class BoxShadowsComponent {

}
