import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

@Pipe({
  name: 'codeSample'
})
export class CodeSamplePipe implements PipeTransform {

  constructor(private _http : HttpClient)
  {}

  transform(value: string): any {
    return this.fetchFile(value);
  }

  private fetchFile(url: string): Observable<any> {   
      return this._http.get('/assets/' + url, { responseType: 'text' }).pipe(
        publishReplay(1),
        refCount(),
        catchError((err: Error) => {
          console.error('[NgxHighlight]: Unable to fetch the URL!', err.message);
          return '';
        })
      );
  }

}
