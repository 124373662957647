import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss']
})
export class TypographyComponent implements OnInit {

  codeUrl = 'typography/code-1';

  constructor() {}

  ngOnInit(): void {
  }
}
