<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Borders</h1>
          <p class="lead">Add border or border-radius to elements with <code>.border</code>, <code>.border-radius</code>, <code>.border-radius-sm</code> or <code>.border-radius-lg</code>
          </p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class=" doc-boxes mb-5">
      <div class="row">
          <div class="col-12">
              <h5></h5>
          </div>
          <div class="col-12 mb-5">
              <code>.border</code>
              <div class="box mt-4 mb-2 border">
              </div>
          </div>
          <div class="col-12 mb-5">
              <code>.border-radius border</code>
              <div class="box mt-4 mb-2 border-radius border">
              </div>
          </div>
          <div class="col-12 mb-5">
              <code>.border-radius-sm border</code>
              <div class="box mt-4 mb-2 border-radius-sm border">
              </div>
          </div>
          <div class="col-12 mb-5">
              <code>.border-radius-lg border</code>
              <div class="box mt-4 mb-2 border-radius-lg border">
              </div>
          </div>
      </div>
  </div>
</section>