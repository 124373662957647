<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Badges</h1>
          <p class="lead">Small count and labeling component
          </p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Contextual variations</h2>
          <p>Add any of the below mentioned modifier classes to change the appearance of a badge.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <span class="badge badge-primary">Primary</span>
              <span class="badge badge-secondary">Secondary</span>
              <span class="badge badge-success">Success</span>
              <span class="badge badge-danger">Danger</span>
              <span class="badge badge-warning">Warning</span>
              <span class="badge badge-light">Light</span>
              <span class="badge badge-dark">Dark</span>
          </div>
          <div class="col-12 mb-4">
              <span class="badge badge-primary-soft">Primary</span>
              <span class="badge badge-success-soft">Success</span>
              <span class="badge badge-danger-soft">Danger</span>
              <span class="badge badge-warning-soft">Warning</span>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'badges/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Pill badges</h2>
          <p>Use the <code>.badge-pill</code> modifier class to make badges more rounded.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <span class="badge badge-pill badge-primary">Primary</span>
              <span class="badge badge-pill badge-secondary">Secondary</span>
              <span class="badge badge-pill badge-success">Success</span>
              <span class="badge badge-pill badge-danger">Danger</span>
              <span class="badge badge-pill badge-warning">Warning</span>
              <span class="badge badge-pill badge-light">Light</span>
              <span class="badge badge-pill badge-dark">Dark</span>
          </div>
          <div class="col-12 mb-4">
              <span class="badge badge-pill badge-primary-soft">Primary</span>
              <span class="badge badge-pill badge-success-soft">Success</span>
              <span class="badge badge-pill badge-danger-soft">Danger</span>
              <span class="badge badge-pill badge-warning-soft">Warning</span>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'badges/code-2.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Badges with status indicator</h2>
          <p>Add <code>.status-danger</code> or <code>.status-success</code> to <code>.badge-light</code></p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="col-12 mb-4">
              <span class="badge badge-pill badge-light status-danger">12</span>
              <span class="badge badge-pill badge-light status-success">10</span>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'badges/code-3.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>