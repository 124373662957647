<div class=" doc-hero">
  <div class="row">
      <div class="col">
          <h1 class="huge">Typography</h1>
          <p class="lead"></p>
      </div>
  </div>
</div>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Sans serif headings</h2>
          <p>Named classes apply to any tag. For example, you can apply heading <code>.heading-lg</code> and <code>.heading-xl</code> to h1 tag as well as to h2, h3, etc.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h1 class="heading-xl">Heading 1 xl</h1>
              <h1 class="heading-lg">Heading 1 lg</h1>
              <h1 class="h1">Heading 1</h1>
              <h2 class="h2">Heading 2</h2>
              <h3 class="h3">Heading 3</h3>
              <h4 class="h4">Heading 4</h4>
              <h5 class="h5">
                  <span class="pretitle">Projekt 2343</span><br>
                  Heading 5
              </h5>
              <h6 class="h6">Heading 6</h6>
          </div>
      </div>
  </div>
  <div class="doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
                <code [highlight]="'typography/code-1.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
                </code>
            </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Serif headings</h2>
          <p>Named classes apply to any tag. For example, you can apply heading <code>.heading-lg</code> and <code>.heading-xl</code> to h1 tag as well as to h2, h3, etc.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <h1 class="heading-xl heading-serif">Heading 1 xl</h1>
              <h1 class="heading-lg heading-serif">Heading 1 lg</h1>
              <h1 class="h1 heading-serif">Heading 1</h1>
              <h2 class="h2 heading-serif">Heading 2</h2>
              <h3 class="h3 heading-serif">Heading 3</h3>
              <h4 class="h4 heading-serif">Heading 4</h4>
              <h5 class="h5 heading-serif">Heading 5</h5>
              <h6 class="h6 heading-serif">Heading 6</h6>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'typography/code-2.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Lead</h2>
          <p>Make a paragraph stand out by adding <code>.lead</code>.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <p class="lead">Mit skillconomy finden Sie innerhalb von 14 Tagen passende,
                  wechselbereite Bewerber:innen – auch für schwer zu besetzende Stellen.</p>
          </div>
      </div>
  </div>
</section>

<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Inline text elements</h2>
          <p>Styling for common inline HTML5 elements.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <p>You can use the mark tag to <mark>highlight</mark> text.</p>
              <p><del>This line of text is meant to be treated as deleted text.</del></p>
              <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
              <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
              <p><u>This line of text will render as underlined.</u></p>
              <p><small>This line of text is meant to be treated as fine print.</small></p>
              <p class="smaller">This line of text is meant to be treated as super fine print.</p>
              <p><strong>This line rendered as bold text.</strong></p>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'typography/code-3.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>


<section class=" doc-section">
  <div class="row">
      <div class="sc-example mb-4 col-12">
          <h2>Lists unstyled</h2>
          <p>Remove the default <code>list-style</code> and left margin on list items (immediate children only). This only applies to immediate children list items, meaning you will need to add the class for any nested lists as well.</p>
      </div>
  </div>
  <div class=" doc-html">
      <div class="row">
          <div class="sc-example mb-4 col-12">
              <ul class="list-unstyled">
                  <li>This is a list.</li>
                  <li>It appears completely unstyled.</li>
                  <li>Structurally, it's still a list.</li>
                  <li>However, this style only applies to immediate child elements.</li>
                  <li>Nested lists:
                      <ul>
                          <li>are unaffected by this style</li>
                          <li>will still show a bullet</li>
                          <li>and have appropriate left margin</li>
                      </ul>
                  </li>
                  <li>This may still come in handy in some situations.</li>
              </ul>
          </div>
      </div>
  </div>
  <div class=" doc-example-code">
      <div class="row">
          <div class="col">
            <pre>
              <code [highlight]="'typography/code-4.example.html' | codeSample | async" [languages]="['html']" [lineNumbers]="true">                    
              </code>
            </pre>
          </div>
      </div>
  </div>
</section>